<template>
  <v-card outlined tile style="border: none;">
    <v-card-title v-if="showCategoryName" class="pb-0">
      {{ category }}
    </v-card-title>
    <v-card-text>
      <template v-for="(n, row) in nbRows">
        <table :key="row" class="profile-table">
          <tr>
            <th v-for="(item, i) in rowItems(row)" :key="i">
              <v-tooltip right :disabled="!isNotFromSdf(item)">
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.initialName || item.predictionDisplayName }}
                  </span>

                  <v-slider
                    v-show="showPriority && isNotFromSdf(item)"
                    :value="item.priority"
                    :readonly="priorityReadOnly"
                    hide-details
                    height="12px"
                    step="1"
                    max="5"
                    min="1"
                    ticks="always"
                    tick-size="4"
                    :disabled="item.objectiveServer == null"
                    @change="changePriority(item, $event)"
                  >
                    <template #label>
                      <p class="slider-priority ml-1">
                        {{ item.priority }}
                      </p>
                    </template>
                  </v-slider>
                </template>
                <span>
                  Model: {{ item.predictionName }}<br>
                  Unit:  {{ item.predictionUnit }}
                </span>
              </v-tooltip>
            </th>
          </tr>
          <tr v-if="showValues">
            <td
              v-for="(item, i) in rowItems(row)"
              :key="i"
              :class="`profile-cell ${item.activity}`"
            >
              <v-tooltip right :disabled="!isNotFromSdf(item)">
                <template #activator="{ on, attrs }">
                  <v-row v-bind="attrs" v-on="on">
                    <v-col
                      :cols="showCredibility && item.credibility != null ? 7 : 12"
                    >
                      <div
                        v-if="item.isGroup && item.info.targets.length > 0"
                        class="clickable group-cell"
                        @click="openDialog(item)"
                      >
                        <template
                          v-for="(count, status) in item.info.count"
                        >
                          <strong :key="status" class="group-inactive">
                            {{ count }}
                          </strong> / {{ item.info.targets.length }}
                        </template>
                      </div>
                      <div v-else>
                        <strong>{{ item.initial }}</strong>
                        <template v-if="item.predictionModel != null">
                          <span v-if="item.initial != null"> / </span>
                          {{ item.predicted != null ? item.predicted : item.status == "FAILED" ? 'FAILED' : "..." }}
                        </template>
                        <template v-else-if="item.initial != null">
                          <strong>{{ item.initial }}</strong>
                        </template>
                        <template v-else>
                          <span>{{ item.predicted }}</span>
                        </template>
                      </div>
                    </v-col>
                    <v-divider
                      v-if="showCredibility && item.credibility != null"
                      vertical
                    />
                    <v-col
                      v-if="showCredibility && item.credibility != null"
                      class="pl-0"
                      :cols="5"
                    >
                      <!-- <v-divider :thickness="8" color="black" vertical /> -->
                      <span class="black--text font-weight-bold">
                        {{ item.credibility }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <span>
                  Model: {{ item.predictionName }}<br>
                  Unit:  {{ item.predictionUnit }}
                </span>
              </v-tooltip>
            </td>
          </tr>
        </table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TargetProfileCategory',
  components: {},
  props: {
    category: {
      type: String,
      required: true
    },
    nbColumns: {
      type: Number,
      required: true
    },
    nbRows: {
      type: Number,
      required: true
    },
    profile: {
      type: Array,
      default: () => []
    },
    showPriority: {
      type: Boolean,
      default: false
    },
    showCategoryName: {
      type: Boolean,
      default: false
    },
    showValues: {
      type: Boolean,
      default: true
    },
    priorityReadOnly: {
      type: Boolean,
      default: false
    },
    showCredibility: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  methods: {
    rowItems(row) {
      return this.profile.slice(
        row * this.nbColumns, (row + 1) * this.nbColumns
      );
    },
    openDialog(target) {
      this.$emit('openDialog', target);
    },
    changePriority(item, priority) {
      this.$emit('changePriority', item, priority);
    },
    isNotFromSdf(item) {
      return item.predictionModel != null;
    }
  }
};
</script>

<style scoped>
.colorBox {
    width: 15px;
    height: 15px;
    border: 2px solid black;
    display: inline-block;
}
.group-cell {
    border: 1px solid black;
}
:deep(.v-slider__tick--filled) {
    background-color: rgba(0, 0, 0, 0.5);
}
.slider-priority {
    font-size: 12px;
}
:deep(.v-slider__thumb:before) {
    background: none;
}
:deep(.v-slider__thumb) {
    width: 9px;
    height: 9px;
}
</style>
